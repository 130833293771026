<template>
	<div class="recruitStudent_page">
		<Banner :adCategory="adCategory"></Banner>
		<div class="module1">
			<div class="content flex">
				<div class="fl">
					<div class="title display">{{ titleName }}</div>
					<div class="classify">
						<div
							class="list Between pointer"
							v-for="(item, index) in classifyList"
							:key="index"
							@click="changeBtn(item, index)"
						>
							<div class="name" :class="activeIndex == index ? 'active' : ''">
								{{ item.typeName }}
							</div>
							<img
								class="icon_right"
								src="@/assets/img/icon_right1.png"
								alt=""
							/>
							<div class="line" v-if="activeIndex == index"></div>
						</div>
					</div>
				</div>
				<div class="fr">
					<div class="head">
						<div class="titleName">{{ title }}</div>
						<div class="line"></div>
					</div>
					<div class="content_box" v-if="title != '合作联系'">
						<div v-if="isDetails == false">
							<div
								class="list Between pointer"
								v-for="(item, index) in briefIntroduction"
								:key="index"
								@click="selectBtn(item)"
							>
								<div class="cont row">{{ item.title }}</div>
								<div class="time">{{ item.createTime }}</div>
							</div>
						</div>
						<div class="details_box" v-else>
							<div class="head_box">
								<div class="title">{{ formData.title }}</div>
								<p class="time_num">
									发表时间：{{ formData.createTime }} &nbsp;&nbsp;&nbsp;
									浏览量：{{ formData.browseNum }}
								</p>
							</div>
							<div class="richText" v-html="formData.content"></div>
						</div>
						<div
							class="page_box flex justify-end"
							v-if="isDetails == false && briefIntroduction.length != 0"
						>
							<el-pagination
								@size-change="handleSizeChange"
								@current-change="handleCurrentChange"
								background
								:current-page="1"
								layout="prev, pager, next"
								:total="total"
								:page-size="params.limit"
							>
							</el-pagination>
						</div>
					</div>
					<div class="content_box" v-else>
						<div class="details_box">
							<div class="head_box">
								<div class="title">{{ briefIntroduction[0].title }}</div>
								<p class="time_num">
									发表时间：{{
										briefIntroduction[0].createTime
									}}
									&nbsp;&nbsp;&nbsp; 浏览量：{{
										briefIntroduction[0].browseNum
									}}
								</p>
							</div>
							<div class="richText" v-html="briefIntroduction[0].content"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Banner from '@/components/banner'
export default {
	components: {
		Banner,
	},
	data() {
		return {
			adCategory: null,
			activeIndex: 0,
			isDetails: false,
			title: '',
			classifyList: [],
			briefIntroduction: [],
			params: {
				typeId: null,
				page: 1,
				limit: 10,
			},
			formData: {},
			total: 0,
			type: null,
			titleName: '',
		}
	},
	// 监听路由变化
	watch: {
		$route: 'urlName',
	},
	created() {
		this.type = this.$route.query.type
		if (this.type == 1) {
			this.titleName = '招生就业'
			this.adCategory = 10
		} else {
			this.titleName = '对外合作'
			this.adCategory = 11
		}
		this.getClassify()
	},
	methods: {
		// 监听url参数变化
		urlName() {
			this.type = this.$route.query.type
			if (this.type == 1) {
				this.titleName = '招生就业'
				this.adCategory = 10
			} else {
				this.titleName = '对外合作'
				this.adCategory = 11
			}
			this.getClassify()
		},
		// 查询分类
		getClassify() {
			this.api
				.findAdmissionsCooperationType({ type: this.type })
				.then((res) => {
					this.classifyList = res.data || []
					if (this.classifyList.length != 0) {
						this.params.typeId = this.classifyList[0].id
						this.title = this.classifyList[0].typeName
						this.getList()
					}
				})
		},
		// 查询分类
		getList() {
			this.api.findPageAdmissionsCooperation(this.params).then((res) => {
				this.briefIntroduction = res.data || []
				this.total = res.count
			})
		},
		// 选择分类
		changeBtn(item, index) {
			this.params.typeId = item.id
			this.params.page = 1
			this.title = item.typeName
			this.activeIndex = index
			this.isDetails = false
			this.getList()
		},
		//选择列表
		selectBtn(item) {
			this.formData = item
			this.isDetails = true
		},
		// 分页
		handleSizeChange(val) {},
		handleCurrentChange(val) {
			this.params.page = val
			this.getList()
		},
	},
}
</script>
<style lang="scss" scoped>
.recruitStudent_page {
	.module1 {
		width: 100%;
		background: white;
		margin-top: 29px;
		margin-bottom: 50px;

		.fl {
			width: 268px;
			.title {
				width: 268px;
				height: 80px;
				background: #003c7e;
				font-size: 30px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffffff;
			}
			.classify {
				.list {
					width: 100%;
					height: 54px;
					background: #f4f5f5;
					margin-bottom: 1px;
					padding-left: 31px;
					padding-right: 18px;
					box-sizing: border-box;
					font-size: 18px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #333333;
					position: relative;
					.icon_right {
						width: 6px;
						height: 12px;
					}
					.line {
						width: 2px;
						height: 20px;
						background: #003c7e;
						position: absolute;
						top: 50%;
						left: 0;
						margin-top: -10px;
					}
					.active {
						color: #003c7e !important;
					}
				}
			}
		}

		.fr {
			width: 900px;
			margin-left: 31px;
			.head {
				width: 100%;
				border-bottom: 1px solid #dedede;
				padding-top: 29px;
				padding-bottom: 13px;
				position: relative;
				.titleName {
					font-size: 24px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #003c7e;
					line-height: 24px;
				}
				.line {
					width: 111px;
					height: 2px;
					background: #003c7e;
					position: absolute;
					left: 0;
					bottom: -1px;
				}
			}
			.content_box {
				.list:hover {
					.cont {
						color: #003c7e !important;
					}
					.time {
						color: #003c7e !important;
					}
				}
				.list {
					width: 100%;
					height: 57px;
					border-bottom: 1px solid #dedede;
					padding-left: 10px;
					padding-right: 5px;
					.cont {
						width: 699px;
						font-size: 16px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #333333;
					}

					.time {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #888888;
					}
				}
				.details_box {
					margin-bottom: 71px;
					.head_box {
						width: 900px;
						height: 114px;
						background: rgba(222, 222, 222, 0.2);
						padding-top: 34px;
						padding-left: 41px;
						box-sizing: border-box;
						.title {
							font-size: 24px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #333333;
							line-height: 24px;
						}
						.time_num {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #888888;
							line-height: 14px;
							margin-top: 17px;
						}
					}
					.richText {
						width: 900px;
						padding: 10px;
						box-sizing: border-box;
					}
				}
			}
			.page_box {
				margin-top: 59px;
			}
		}
	}
}
</style>
<style>
.el-pager li.active {
	background: #003c7e !important;
}
.el-pagination.is-background .el-pager li:not(.disabled):hover {
	color: #003c7e !important;
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
	color: white !important;
}
.richText p {
	font-family: Microsoft YaHei;
	display: block;
	-webkit-margin-before: 1em;
	margin-block-start: 1em;
	-webkit-margin-after: 1em;
	margin-block-end: 1em;
	line-height: 24px;
}
.richText img {
	max-width: 100%;
}
</style>